import './css/main.css';

// import './js/isotope.js';
// import './js/gallery.js';
// import './js/gsap.js';



// // When using fullPage extensions replace the previous import
// // of fullpage.js for this file
// //import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

// // Initializing it
// var fullPageInstance = new fullpage('#fullPage', {
//     licenseKey: 'QNXU7-JVUV6-FJ48I-SJFI7-NQVVJ',
//     navigation: true,
//     sectionsColor:['#ff5f45', '#0798ec', '#fc6c7c'],
//     scrollBar: true,
//     // navigation: true
// });





// function loadGsapIfNeeded() {
//     const gsap1Element = document.getElementById('gsap1');
//     if (gsap1Element) {
//         import(/* webpackChunkName: "gsap" */ "./js/gsap1.js").then(module => {
//             const gsap = module.default;
//             gsap();
//         }).catch(error => {
//             console.error("Error loading GSAP module:", error);
//         });
//     }
// }


function loadGsap2IfNeeded() {
    const gsap2Element = document.getElementById('gsap2');
    if (gsap2Element) {
        import(/* webpackChunkName: "gsap" */ "./js/gsap2.js").then(module => {
            const gsap = module.default;
            gsap();
        }).catch(error => {
            console.error("Error loading GSAP2module:", error);
        });
    }
}


// function loadIsotopeIfNeeded() {
//     const isotopeElement = document.querySelector('.isoGrid');
//     if (isotopeElement) {
//         import(/* webpackChunkName: "isotope" */ "./js/isotope.js").then(module => {
//             const isotope = module.default;
//             isotope();
//         }).catch(error => {
//             console.error("Error loading Isotope module:", error);
//         });
//     }
// }

function loadGalleryIfNeeded() {
    const galleryElement = document.querySelector('.gallery');
    if (galleryElement) {
        import(/* webpackChunkName: "gallery" */ "./js/gallery.js").then(module => {
            const gallery = module.default;
            gallery();
        }).catch(error => {
            console.error("Error loading Gallery module:", error);
        });
    }
}

function loadFullpageIfNeeded() {
    const fullpageElement = document.getElementById('fullpage');
    console.log('Checking for #fullpage element:', fullpageElement);
    if (fullpageElement) {
        import(/* webpackChunkName: "fullpage" */ "./js/fullpage.js").then(module => {
            console.log("Fullpage.js module loaded:", module);
            const fullpage = module.default;
            fullpage(); // Initialize fullpage.js
        }).catch(error => {
            console.error("Error loading Fullpage module:", error);
        });
    }
}



// Execute the function when DOM is ready
document.addEventListener('DOMContentLoaded', () => {
    // loadGsapIfNeeded(); // Currently commented out in your code
    loadGsap2IfNeeded();
    // loadIsotopeIfNeeded();
    loadGalleryIfNeeded();
    loadFullpageIfNeeded(); // Add the new function here
});

// import './js/barba.js';

// import './js/textAdjust.js';


